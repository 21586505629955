import Validator from "validator";
import moment from "moment";

const BME = "bme";

//const MOBILE_PHONE_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}([\s]{0,1}[0-9]*)*$/gm;
const MOBILE_PHONE_REGEX = /^\d+$/gm;

export function validateMobileNumber(n) {
  n = n + "";
  if (n.length > 10 || n.length < 6) return false;
  return n.match(MOBILE_PHONE_REGEX);
  return true;
}

export function CheckIfDateIsGONull(date) {
  return (
    date.getDay() === 1 && date.getMonth() === 0 && date.getYear() === -1899
  );
}

export function translateError(error) {
  if (error && error.message) {
    const msg = error.message;
    console.log("A ", msg);
    if (msg === "CLIENTID-NOT-FOUND") {
      error.message =
        "ClientID non trovato. Contatta assistenza o controlla l'URL del browser";
    } else if (msg === "Network request failed") {
      error.message = "Errore di sistema. Riprova o contatta l'assistenza";
    } else if (
      msg === "The password is invalid or the user does not have a password."
    ) {
      error.message = "Password sbagliata";
    } else if (
      msg === "The email address is already in use by another account."
    ) {
      error.message =
        "L'indirizzo email inserito è già stato utilizzato per creare un account";
    } else if (
      msg ===
      "There is no user record corresponding to this identifier. The user may have been deleted."
    ) {
      error.message =
        "Non è stato trovato un account corrispondente all'indirizzo inserito";
    } else if (msg === "Rewarding action is duplicated") {
      error.message =
        "L'interazione che hai provato ad inserire risulta già stata inserita precedentemente.";
    } else if (msg === "Daily rewarding action number reached") {
      error.message =
        "Hai già raggiunto il limite giornaliero per interazioni di questo tipo";
    } else if (msg === "Max rewarding action number reached") {
      error.message =
        "Hai raggiunto il limite massimo per interazioni di questo tipo";
    } else if (
      msg === "No permission found for user logged in for the specified API"
    ) {
      error.message =
        "Permessi non validi per accedere a questa risorsa. Risorsa appartenente ad un altro operatore o ruoli non adeguati";
    } else if (msg === "The QRCode is expired, please refresh it") {
      error.message =
        "Il QRCode risulta scaduto, eseguire il refresh e riprovare";
    } else if (msg === "GDSP-LOGIN-FAILED") {
      error.message =
        "Login fallita! Controlla le tue credenziali SposiClub e ritenta o contatta il servizio assistenza";
    } else if (
      msg ===
      "VALIDATION_ERROR: Product has a dayBeforeUse constraint not respected"
    ) {
      error.message =
        "Premio in stato di pending. Non ancora possibile utilizzarlo";
    } else if (msg === "VALIDATION_ERROR: Not enough value to get the prize") {
      error.message = "Non hai abbastanza punti per richiedere questo premio";
    } else if (msg === "USER_NOT_ACTIVE") {
      error.message =
        "Il tuo profilo risulta disattivato. Contatta il support per risolvere il problema";
    } else if (msg === "USEPERMISSION_WRONG_MERCHANT") {
      error.message =
        "Questo codice appartiene ad un premio relativo ad un altro negozio. Non puoi validarlo.";
    } else if (msg === "Email not authorized to access this client") {
      error.message =
        "Il tuo account è stato momentaneamente bloccato. Per informazioni contatta il servizio assistenza";
    } else if (msg === "FAIR_OWNED_BY_OTHER_MERCHANT") {
      error.message =
        "Il pass scannerizzato appartiene ad una fiera non gestita da questo operatore.";
    } else if (msg === "ID token has been revoked") {
      error.message =
        "Il tuo account è stato momentaneamente bloccato. Per informazioni contatta il servizio assistenza";
    } else if (
      msg === "The user account has been disabled by an administrator."
    ) {
      error.message =
        "Il tuo account è stato momentaneamente bloccato. Per informazioni contatta il servizio assistenza";
    } else if (msg === "Claim cl_id not set in token") {
      error.message = "La sessione è scaduta. Rieffettua la login";
    } else if (msg === "QRCode belongs to another merchant") {
      error.message =
        "Il QRCode scansionato non appartiene al negozio per cui è stata registrata l'interazione";
    } else if (msg === "MAX_PRODUCTS_PER_USER_REACHED") {
      error.message =
        "Ti sei già prenotato per questo evento";
    } else if (msg === "capacity") {
      error.message =
        "Non ci sono più posti disponibili per la fascia oraria selezionata";
      } else if (msg === "content-limit") {
        error.message =
          "Hai già fissato un appuntamento con questo espositore. Ti ricordiamo che durante la fiera ogni partecipante può prenotare un solo appuntamento per ciascun espositore ma è permesso fissare appuntamenti con diversi espositori. Nel caso desiderassi semplicemente cambiare orario all'appuntamento già fissato, apri la tua agenda degli appuntamenti e cancella la prenotazione esistente con lo stesso espositore, dopodiché prenota l'appuntamento all'orario che desideri.";
    } else {
      console.error(msg);
      error.message =
        "Qualcosa non funziona? Consulta il menù SERVE AIUTO? e troverai assistenza";
    }
  }

  return error;
}

export function traslateStatus(s) {
  if (s === "APPROVED") return "Confermata";
  if (s === "PENDING") return "Da confermare";
  if (s === "CANCELED") return "Cancellata";
  return s;
}

export function goBackFromView(currentView, ricercaState) {
  let newCurrentView = null;
  let newRicercaState = ricercaState;
  if (currentView === "search" || currentView === "form")
    if (ricercaState.isNested) {
      // se non si tratta della prima pagina di ricerca il back è ancora all'interno della ricerca
      newCurrentView = "search";
      newRicercaState = ricercaState.backState();
    } else {
      // se si tratta della prima pagina di ricerca, il back è la pagina principale
      newCurrentView = "main";
    }
  if (currentView === "form") {
    // il back della pagina fornitore è sempre una ricerca
    newCurrentView = "search";
    if (ricercaState.isNested) {
      // se la ricerca è annidata, significa che sono passato per una ricerca interna
      newRicercaState = ricercaState;
    }
  }
  if (currentView === "result") newCurrentView = "form";
  if (currentView === "qrCode") newCurrentView = "result";

  return { view: newCurrentView, subView: newRicercaState };
}

export function setPrivacy(privacy) {
  let bme = getUserInfo();
  bme.privacy = privacy;
  setUserInfo(bme);
}

export function getPrivacy() {
  let bme = getUserInfo();
  return bme.privacy;
}

export function isFutureDate(date) {
  const today = moment();
  date = moment(date);
  return today.isBefore(date);
}

export function checkUnauthError(errorCode, firebase, bmarkenAPI) {
  if (
    errorCode === 401 &&
    bmarkenAPI &&
    bmarkenAPI !== null &&
    firebase &&
    firebase !== null
  ) {
    firebase
      .doSignOut()
      .then(() => {
        bmarkenAPI.logout();
        return true;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  }

  return false;
}

export function formatDate(date, type) {
  if (date && date !== null && date !== "") {
    let month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    if (type === "server") return [year, month, day].join("-");
    else return [day, month, year].join("/");
  }

  return "";
}

export function validateRoles(roleWanted, userRole, strict) {
  if (strict) return roleWanted === userRole;

  return (
    roleWanted === userRole ||
    (roleWanted === "M" && (userRole === "GM" || userRole === "TM")) ||
    (roleWanted === "GM" && userRole === "TM")
  );
}

export function getUserInfo() {
  let bme = localStorage.getItem("bme");
  if (!bme || bme === null || bme === "") {
    bme = {
      language: "",
      user_email: "",
      user_id: "",
      user_name: "",
      role: "",
      privacy: false,
      business: "",
      business_name: "",
      business_logo: "",
      token_info: {},
      firebase_token_id: "",
    };
    localStorage.setItem(BME, JSON.stringify(bme));
    return bme;
  } else {
    return JSON.parse(bme);
  }
}

export function setUserInfo(bme) {
  localStorage.setItem(BME, JSON.stringify(bme));
}

export function removeUserInfo() {
  localStorage.removeItem(BME);
}

export function validateUUID(id) {
  return Validator.isUUID(id);
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

//Tutti i numeri che riguardano quantità provenienti dal backend sono rappresentati come interi
//con le due cifre meno significative che rappresentano i due decimali
//Ritorna il numero diviso 100 e con soli due decimali
export function parseBmeIntToFloat(n) {
  if (!n) {
    return n;
  }

  if (Validator.isInt(n.toString(10))) {
    let nf = (n / 100.0).toFixed(2);
    return nf;
  }

  return n;
}

export function validateEmail(s) {
  if (s === null || s === undefined) return false;

  return Validator.isEmail(s);
}

export function validateFloat(n) {
  if (!n) {
    return n;
  }

  return Validator.isFloat(n.toString(10));
}

//Tutti i numeri che riguardano quantità provenienti dal backend sono rappresentati come interi
//con le due cifre meno significative che rappresentano i due decimali
//Ritorna il numero moltiplicato per 100 e con soli due decimali
export function parseFloatToBmeInt(n) {
  if (!n) {
    return n;
  }

  if (Validator.isFloat(n.toString(10))) {
    let nf = (n * 100).toFixed(2);
    return parseInt(nf);
  }

  return n;
}

//Tutti i numeri che riguardano quantità provenienti dal backend sono rappresentati come interi
//con le due cifre meno significative che rappresentano i due decimali
//Ritorna la parte intera del numero diviso 100
/*export function parseBmeIntToInt(n) {
  if (!n) {
    return n;
  }

  if (Validator.isInt(n.toString(10))) {
    let ni = parseInt(n / 100);
    return ni;
  }

  return n;
}*/
export function parseBmeIntToInt(n) {
  if (n == null || typeof n !== 'number' || !isFinite(n) || Math.floor(n) !== n) {
    return n;
  }

  return Math.floor(n / 100);
}

//ritorna una stringa in cui:
// - se lingua Inglese: non cambia nulla
// - altrimenti sostituisce la virgola col punto
export function parseLocalizedNumber(s, l) {
  if (l === "en" || l === "en-GB" || l === "EN") {
    return s;
  }

  return s.replace(",", ".");
}

//SOlo con caratteri di separazione singoli
export function getIDsFromCode(code, prefix, idQty) {
  const prefixIndex = code.indexOf(prefix);
  //console.log(code)
  if (prefixIndex < 0) {
    return null;
  }

  let ids = [];
  code = code.substring(prefix.length);
  //console.log(code)
  for (let i = 0; i < idQty; i++) {
    const id = code.substring(0, 36);
    //console.log(id)
    if (!validateUUID(id)) {
      return null;
    }

    ids.push(id);
    code = code.substring(36);
    if (code.length > 0) {
      code = code.substring(1); //rimuovo il carattere di separazione
    }
  }

  return ids;
}

export function translateAction(act) {
  if (act === "PURCHASE") return "Acquisto";
  if (act === "VISIT") return "Visita";
  if (act === "FAIR") return "Fiera";
  if (act === "REVIEW") return "Recensione";
  if (act === "GIFT") return "Benvenuto";
  if (act === "PROFILE50") return "Bonus profilo";
  if (act === "ENTRY") return "Ingresso";
  return act;
}

export function parse_query_string(query) {
  const query_string = {};
  if (!query) return query_string;
  if (query.substring(0, 1) == "?") query = query.substring(1);
  const vars = query.split("&");

  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    const key = decodeURIComponent(pair[0]);
    const value = decodeURIComponent(pair[1]);
    // If first entry with this name
    if (typeof query_string[key] === "undefined") {
      query_string[key] = decodeURIComponent(value);
      // If second entry with this name
    } else if (typeof query_string[key] === "string") {
      const arr = [query_string[key], decodeURIComponent(value)];
      query_string[key] = arr;
      // If third or later entry with this name
    } else {
      query_string[key].push(decodeURIComponent(value));
    }
  }
  return query_string;
}

export function getCompleteAddress(merchant) {
  if (!merchant.location) {
    return "";
  }
  return `${merchant.location.street || ""} ${merchant.location.city || ""} ${merchant.location.province ? "(" + merchant.location.province + ")" : "" }`
}